@font-face {
  font-family: "KazimirText";

  src: url("fonts/KazimirText-Semibold.woff") format("woff"),
    url("fonts/KazimirText-Semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "KazimirText";

  src: url("fonts/KazimirText-Regular.woff") format("woff"),
    url("fonts/KazimirText-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";

  src: url("fonts/Rubik-Black.woff") format("woff"),
    url("fonts/Rubik-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";

  src: url("fonts/Rubik-SemiBold.woff") format("woff"),
    url("fonts/Rubik-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";

  src: url("fonts/Rubik-Medium.woff") format("woff"),
    url("fonts/Rubik-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Rubik";

  src: url("fonts/Rubik-Regular.woff") format("woff"),
    url("fonts/Rubik-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;

  background-color: #edeee9;
  margin: 0;
  min-width: 320px;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
